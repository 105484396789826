import config from '../../config.js'
import { customPrefix } from './index'
import moment from 'moment'

const { Region, ClientBucket } = config

const primary = '#115e67'
const secondary = '#daaa00'
const light = '#f4eddb'
const dark = '#362b28'
const info = '#48a8d4'
const success = '#71af2e'
const warning = '#cf7f0f'
const danger = '#c4390f'

export default {
  name: 'ITG (Demo) – Continue professionele vorming',
  description:
    'E-learningplatform voor continue professionele vorming reisgeneeskunde — Instituut voor Tropische Geneeskunde',
  colors: { primary, secondary, light, dark, info, success, warning, danger },
  fonts: {
    headings: 'Effra',
    default: 'Lora'
  },
  storage: {
    // Note trailing slash!
    client: `https://s3-${Region}.amazonaws.com/${ClientBucket}/`,
    assets: `https://s3-${Region}.amazonaws.com/${ClientBucket}/${customPrefix.public}`
  },
  images: {
    // fileName, extension, altText: ['logo','png','Logo E-learning']
    header: ['ITM-logo_white_rgb_web', 'png', 'Logo ITM'],
    footer: ['ITM-logo_col_rgb_web', 'png', 'Logo ITM'],
    intro: ['home-side', 'jpg', 'Gezondheidprofessionals leren continu bij']
  },
  copy: {
    // supports MD
    intro:
      'Welkom aan alle gezondheidsprofessionals op dit leerplatform voor continue professionele vorming van het Instituut voor Tropische Geneeskunde. Wij hopen dat u een nuttige en aangename tijd met ons doorbrengt. Wij verwelkomen uw feedback en vragen [via mail](mailto:avondseminaries@itg.be).',
    welcome:
      'Welkom aan alle gezondheidsprofessionals op dit leerplatform voor continue professionele vorming van het Instituut voor Tropische Geneeskunde. Wij hopen dat u een nuttige en aangename tijd met ons doorbrengt.',
    footer: `<div class="col-tablet-8">
      <p>
        <strong>Verantwoordelijke uitgever:</strong>
        <br />
        Marc-Alain Widdowson
      </p>
      <p>
        <strong>Verantwoordelijke medisch-technische inhoud:</strong>
        <br />
        Patrick Soentjens
      </p>
    </div>
    <div class="col-tablet-4">
      <p>© ${moment().format('YYYY')} Instituut voor Tropische Geneeskunde</p>
      <p>Contacteer ons <a href='mailto:avondseminaries@itg.be'>via e-mail</a></p>
    </div>`,
    privacy:
      'Welkom aan alle gezondheidsprofessionals op dit leerplatform voor continue professionele vorming van het Instituut voor Tropische Geneeskunde. Wij hopen dat u een nuttige en aangename tijd met ons doorbrengt.'
  },
  signup: {
    customFields: ['profession', 'riziv']
  },
  ZOOM_API_KEY: 'wHHyhjdBShqhTX8CKy6QCA',
  ZOOM_API_SECRET: '3vumcWNmcTsyIzDzzVxPceCnj4nRoK0Oa4Qk',
  baseUrl: 'https://duoyi4a8cvhhs.cloudfront.net/',
  multilingual: ['nl', 'fr', 'en', 'de']
}
