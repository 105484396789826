import React from 'react'
import { useAlert } from 'react-alert'
import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { initials } from '../../../../_helpers/users'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { COURSE_CERTIFICATE_USERS } from './queries'
import {
  ADD_CERTIFICATE_TO_USER,
  REMOVE_CERTIFICATE_FROM_USER
} from './mutations'

const User = ({ user, queryVars, refetch }) => {
  const alert = useAlert()

  // Mutations
  const [
    addCertificateToUser,
    { loading: addCertificateLoading, error: addCertificateError }
  ] = useMutation(ADD_CERTIFICATE_TO_USER, {
    onCompleted: ({ addCertificateToUser: { success, data } }) => {
      alert.show(success ? 'certificate assigned' : 'an error occurred', {
        type: success ? 'success' : 'danger'
      })
      // refetch()
    }
  })
  const [
    removeCertificateFromUser,
    { loading: removeCertificateLoading, error: removeCertificateError }
  ] = useMutation(REMOVE_CERTIFICATE_FROM_USER, {
    onCompleted: ({ removeCertificateFromUser: { success, data } }) => {
      alert.show(success ? 'certificate removed' : 'an error occurred', {
        type: success ? 'success' : 'danger'
      })
      // refetch()
    }
  })

  // Methods
  const addCertificate = () => {
    if (
      window.confirm(
        `Are you certain you want to assign a certificate to ${user.name ||
          'this user'}?`
      )
    ) {
      addCertificateToUser({
        variables: {
          _id: user._id,
          CourseCertificateInput: {
            course: queryVars.course
          }
        }
      })
    }
  }
  const removeCertificate = () => {
    if (
      window.confirm(
        `Are you certain you want to revoke a certificate from ${user.name ||
          'this user'}?`
      )
    ) {
      removeCertificateFromUser({
        variables: {
          _id: user._id,
          CourseCertificateInput: {
            course: queryVars.course
          }
        }
      })
    }
  }

  // Components
  let actions = []
  // This determines which action/icon they have access to
  let alreadyCertified = false
  if (user.courseCertificates) {
    user.courseCertificates.map(certificate => {
      if (certificate.course === queryVars.course) {
        alreadyCertified = true
      }
      return alreadyCertified
    })
  }
  if (alreadyCertified) {
    actions.removeCertificateAction = {
      color: 'success',
      onClick: removeCertificate,
      loading: removeCertificateLoading,
      error: removeCertificateError,
      icon: 'award'
    }
  } else {
    actions.addCertificateAction = {
      onClick: addCertificate,
      loading: addCertificateLoading,
      error: addCertificateError,
      icon: 'award'
    }
  }

  return (
    <BoardListItem label={initials(user.name)} actions={actions}>
      <strong>{user.name || ''}</strong>
      <br />
      <small>{user.email || ''}</small>
    </BoardListItem>
  )
}

const CourseCertificates = ({
  match: {
    params: { coursePath }
  }
}) => {
  const queryVars = {
    course: coursePath
  }

  const { loading, error, data, refetch } = useQuery(COURSE_CERTIFICATE_USERS, {
    variables: queryVars
  })

  if (loading) {
    return <BoardBody loading />
  }
  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='loading/error'>
          Er ging iets mis bij het laden
        </Translate>
      </BoardBody>
    )
  }

  const { users, course } = data

  return (
    <>
      <BoardHead title={course.name}>
        <em>
          <Translate _id='courseCertificates/title'>
            Toekenning Certificaten beheren
          </Translate>
        </em>
      </BoardHead>
      <BoardBody>
        {users.length <= 0 ? (
          <Translate _id='courseCertificates/no_users'>
            Er zijn geen users
          </Translate>
        ) : (
          <BoardList>
            {users.map((user, i) => {
              return (
                <User
                  key={i}
                  user={user}
                  queryVars={queryVars}
                  refetch={refetch}
                />
              )
            })}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default CourseCertificates
