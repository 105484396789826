import config from '../../config.js'
import { customPrefix } from './index'

const { Region, ClientBucket } = config

const primary = '#3a5743'
const secondary = '#E8AA38'
const light = '#fffaf0'
const dark = '#343a40'

export default {
  name: 'Sona Pona',
  description: '',
  colors: { primary, secondary, light, dark },
  fonts: {
    headings: 'Menlo, Courier',
    default: 'Menlo, Courier'
  },
  storage: {
    client: `https://s3-${Region}.amazonaws.com/${ClientBucket}/`,
    assets: `https://s3-${Region}.amazonaws.com/${ClientBucket}/${customPrefix.public}`
  },
  images: {
    header: ['sona_pona_logo', 'png', 'Sona Pona logo'],
    footer: ['sona_pona_logo', 'png', 'Sona Pona logo'],
    intro: ['sona_pona_banner', 'png', 'Sona Pona: Enkele goede ideeën']
  },
  copy: {
    intro:
      '“Sona pona”, vrij vertaald “enkele goede ideeën", is een vierdelige masterclass waarvan elk deel een ander maatschappelijk systeem behandelt.',
    footer:
      '“Sona pona”, vrij vertaald “enkele goede ideeën", is een vierdelige masterclass waarvan elk deel een ander maatschappelijk systeem behandelt.'
  },
  signup: {
    customFields: []
  }
}
