import config from '../../config.js'
import { customPrefix } from './index'

const { Region, ClientBucket } = config

const primary = '#112357'
const secondary = '#EC9738'

export default {
  name: 'Body & Rights',
  description:
    'E-learning over seksuele en reproductieve gezondheid en rechten',
  colors: { primary, secondary },
  fonts: {
    headings: 'Raleway',
    default: 'Lora'
  },
  storage: {
    // Note trailing slash!
    client: `https://s3-${Region}.amazonaws.com/${ClientBucket}/`,
    assets: `https://s3-${Region}.amazonaws.com/${ClientBucket}/${customPrefix.public}`
  },
  images: {
    // fileName, extension, altText: ['logo','png','Logo E-learning']
    header: ['logo-2021-neg', 'png', 'Body & Rights logo'],
    footer: ['logo-2021-pos', 'png', 'Body & Rights logo'],
    intro: [
      'intro--studying-in-isolation__web',
      'jpg',
      'E-learning over seksuele en reproductieve gezondheid en rechten'
    ]
  },
  copy: {
    // supports MD
    intro: {
      nl: 'Welkom op Body & Rights, een e-tutorial gericht op iedereen die actief is in internationale samenwerking. Het platform biedt de cursus ‘Body & Rights, Thema’s’ en ‘Body & Rights, Getuigenissen’. Wie de cursus ‘Body & Rights, Thema’s’ heeft doorlopen, krijgt een certificaat dat erkend is door de Belgische ontwikkelingssamenwerking.',
      fr: 'Bienvenue à Body & Rights, un tutoriel en ligne adressée à toutes les personnes actives dans la coopération internationale. La plateforme offre la formation “Body & Rights, Thèmes” et “Body & Rights, Témoignages”. L’attestation qui peut être obtenue est reconnue par la Coopération belge au développement.',
      en: 'Welcome to Body & Rights, an e-tutorial aimed at anyone active in international cooperation. The platform provides a course entitled “Body & Rights, Themes” and “Body & Rights, Testimonials”. The certificate that can be obtained by taking ‘Body & Rights, Themes’, is recognised by the Belgian Development Cooperation.'
    },
    footer: 'E-learning over seksuele en reproductieve gezondheid en rechten',
    loginPage: {
      nl: `![Body & Rights](https://s3-eu-west-1.amazonaws.com/body-and-rights-elearning-prod-client/files/BodyRights_fb%20banner_nl-03.png)\n\nWelkom op Body & Rights, een e-tutorial gericht op iedereen die actief is in internationale samenwerking. Het platform biedt de cursus ‘Body & Rights, Thema’s’ en ‘Body & Rights, Getuigenissen’. Thema’s als veilig bevallen, seksueel geweld, kindhuwelijken, genitale verminking, hiv, soa, gezinsplanning en seksuele rechten komen aan bod. Bijzondere aandacht gaat naar jongeren en seksuele minderheden. Daarnaast laten we diplomaten Hannelore Delcour en Dirk Brems, Enabel SRGR-experte Marleen Bosmans en Memisa projectverantwoordelijke Anna Salvati aan het woord over hun ervaringen in het veld.\n\nWie de cursus ‘Body & Rights, Thema’s’ heeft doorlopen, krijgt een certificaat dat erkend is door de Belgische ontwikkelingssamenwerking.\n\nMeer informatie vind je in de [brochure](https://s3-eu-west-1.amazonaws.com/body-and-rights-elearning-prod-client/files/body-rights-brochure-nl-wlY8P.pdf).\n\n@[youtube](wGvZ9wvuYYY)`,
      en: `![Body & Rights](https://s3-eu-west-1.amazonaws.com/body-and-rights-elearning-prod-client/files/BodyRights_fb%20banner_en-09.png)\n\nWelcome to Body & Rights, an e-tutorial aimed at anyone active in international cooperation. The platform provides a course entitled “Body & Rights, Themes” and “Body & Rights, Testimonials”. Themes such as maternal health, sexual violence, child marriage, genital mutilation, HIV, STIs, family planning and sexual rights are discussed. Special attention is paid to young people and sexual minorities. In addition, diplomats Hannelore Delcour and Dirk Brems, Enabel SRHR expert Marleen Bosmans and Memisa project manager Anna Salvati share their concrete experiences in the field.\n\nThe certificate that can be obtained by taking ‘Body & Rights, Themes’, is recognised by the Belgian Development Cooperation.\n\nMore information can be found in this [brochure](https://s3-eu-west-1.amazonaws.com/body-and-rights-elearning-prod-client/files/body-rights-brochure-en-QnQD6.pdf).\n\n@[youtube](wGvZ9wvuYYY)`,
      fr: `![Body & Rights](https://s3-eu-west-1.amazonaws.com/body-and-rights-elearning-prod-client/files/BodyRights_fb%20banner_fr-13.png)\n\nBienvenue à Body & Rights, un tutoriel en ligne adressée à toutes les personnes actives dans la coopération internationale. La plateforme offre la formation “Body & Rights, Thèmes” et “Body & Rights, Témoignages”. Des sujets tels que la maternité sans risque, les violences sexuelles, les mariages d’enfants, les mutilations génitales, le VIH, les MST, le planning familial et les droits sexuels, en donnant des suggestions sur ce que les participants au cours peuvent faire concrètement. Une attention particulière est accordée aux jeunes et aux minorités sexuelles. En outre, les diplomates Hannelore Delcour et Dirk Brems, l'experte en SDSR d'Enabel Marleen Bosmans et la responsable de Projets chez Memisa Anna Salvati sont interviewés sur leurs expériences sur le terrain.\n\nL’attestation qui peut être obtenue est reconnue par la Coopération belge au développement.\n\nVous trouverez plus d’informations dans la [brochure](https://s3-eu-west-1.amazonaws.com/body-and-rights-elearning-prod-client/files/body-rights-brochure-fr-7l3Nd.pdf).\n\n@[youtube](wGvZ9wvuYYY)`,
      default: 'Welcome'
    }
  },
  signup: {
    customFields: ['profession']
  },
  ZOOM_API_KEY: 'wHHyhjdBShqhTX8CKy6QCA',
  ZOOM_API_SECRET: '3vumcWNmcTsyIzDzzVxPceCnj4nRoK0Oa4Qk',
  baseUrl: '/',
  multilingual: ['nl', 'fr', 'en'],
  staticPages: [
    {
      path: 'about',
      label: 'About'
    },
    {
      path: 'contact',
      label: 'Contact'
    }
  ],
  // GA: 'UA-8778479-9'
  matomo: 'bodyandrights.matomo.cloud/container_eAYTjiSe.js'
}
