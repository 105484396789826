import React, { useEffect } from 'react'
import { ZoomMtg } from '@zoomus/websdk'

const joinMeeting = (signature, meetConfig) => {
  ZoomMtg.init({
    leaveUrl: meetConfig.leaveUrl,
    isSupportAV: true,
    success: function (success) {
      document.getElementById('zmmtg-root').style.display = 'block'

      console.log('Init Success ', success)
      ZoomMtg.join({
        meetingNumber: meetConfig.meetingNumber,
        userName: meetConfig.userName,
        signature: signature,
        apiKey: meetConfig.apiKey,
        passWord: meetConfig.passWord,

        success: success => {
          console.log(success)
        },

        error: error => {
          console.log(error)
        }
      })
    }
  })
}

const CallBox = ({ meetConfig }) => {
  useEffect(() => {
    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.0/lib', '/av')
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareJssdk()

    ZoomMtg.generateSignature({
      meetingNumber: meetConfig.meetingNumber,
      apiKey: meetConfig.apiKey,
      apiSecret: meetConfig.apiSecret,

      role: meetConfig.role,
      success: function (res) {
        console.log('res', res)

        joinMeeting(res.result, meetConfig)
      },
      error: error => {
        console.log(error)
      }
    })
  }, [meetConfig])

  return <></>
}

export default CallBox
