import config from '../../config.js'
import { customPrefix } from './index'

const { Region, ClientBucket } = config

const primary = '#556b2f'
const secondary = '#f4a460'
const light = '#fffaf0'
const dark = '#343a40'

export default {
  name: 'E-learning met LEF',
  description: '',
  colors: { primary, secondary, light, dark },
  fonts: {
    headings: 'Raleway',
    default: 'Lora'
  },
  storage: {
    client: `https://s3-${Region}.amazonaws.com/${ClientBucket}/`,
    assets: `https://s3-${Region}.amazonaws.com/${ClientBucket}/${customPrefix.public}`
  },
  images: {
    header: [
      'logo-e-learning-met-lef-white__web',
      'png',
      'E-learning met LEF logo'
    ],
    footer: [
      'logo-e-learning-met-lef-color__web',
      'png',
      'E-learning met LEF logo'
    ],
    intro: [
      'intro-studying-in-isolation-cwCfA',
      'jpg',
      'Studeren in afzondering dankzij E-learning met LEF'
    ]
  },
  copy: {
    welcome:
      'E-learning met LEF is een online leerplatform voor kleine en grote organisaties die gespecialiseerde kennis op een efficiënte manier willen overbrengen aan interne medewerkers en/of een gericht gekozen extern doelpubliek.\n\nHet platform richt zich op het snel en aangenaam overbrengen van kennis die medewerkers of andere deelnemers kunnen inzetten bij hun professionele dagtaak of vrijwilligerswerk. E-learning met LEF kan je autonoom inzetten voor “leren vanop afstand” of integreren in een eigen workshop- of cursusaanbod. Indien gewenst helpt LEF bij het redactionele werk, zoals het ordenen van lesmateriaal in modules, het maken van video’s of hermonteren, vertalen, enzovoort.',
    intro:
      'E-learning met LEF is een online leerplatform voor kleine en grote organisaties die gespecialiseerde kennis op een efficiënte manier willen overbrengen aan interne medewerkers en/of een gericht gekozen extern doelpubliek.\n\nHet platform richt zich op het snel en aangenaam overbrengen van kennis die medewerkers of andere deelnemers kunnen inzetten bij hun professionele dagtaak of vrijwilligerswerk. E-learning met LEF kan je autonoom inzetten voor “leren vanop afstand” of integreren in een eigen workshop- of cursusaanbod. Indien gewenst helpt LEF bij het redactionele werk, zoals het ordenen van lesmateriaal in modules, het maken van video’s of hermonteren, vertalen, enzovoort.',
    footer:
      'E-learning met LEF is een online leerplatform voor kleine en grote organisaties die gespecialiseerde kennis op een efficiënte manier willen overbrengen aan interne medewerkers en/of een gericht gekozen extern doelpubliek.'
  },
  signup: {
    customFields: ['profession']
  },
  ZOOM_API_KEY: 'wHHyhjdBShqhTX8CKy6QCA',
  ZOOM_API_SECRET: '3vumcWNmcTsyIzDzzVxPceCnj4nRoK0Oa4Qk',
  baseUrl: '/',
  multilingual: ['nl']
}
