import config from '../../config.js'
import { customPrefix } from './index'

const { Region, ClientBucket } = config

const primary = '#E03C31'
const secondary = '#FFD700'
const light = '#F0FFF0'
const dark = '#2F4F4F'

export default {
  name: 'JB',
  description: '',
  colors: { primary, secondary, light, dark },
  fonts: {
    headings: 'DejaVu Serif',
    default: 'Trebuchet MS'
  },
  storage: {
    client: `https://s3-${Region}.amazonaws.com/${ClientBucket}/`,
    assets: `https://s3-${Region}.amazonaws.com/${ClientBucket}/${customPrefix.public}`
  },
  images: {
    header: [],
    footer: [],
    intro: []
  },
  copy: {
    intro: 'Welcome on JB’s e-learning platform!',
    footer: 'Made with ❤️ in Lier'
  },
  signup: {
    customFields: []
  }
}
