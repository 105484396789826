import config from '../../config.js'
import { customPrefix } from './index'

const { Region, ClientBucket } = config

const primary = '#403f8f'
const secondary = '#9e9ef0'
const light = '#faf9f7'
const dark = '#343a40'

export default {
  name: 'Cypress testing suite',
  description: '',
  colors: { primary, secondary, light, dark },
  fonts: {
    headings: 'Raleway',
    default: 'Lora'
  },
  storage: {
    // Note trailing slash!
    client: `https://s3-${Region}.amazonaws.com/${ClientBucket}/`,
    assets: `https://s3-${Region}.amazonaws.com/${ClientBucket}/${customPrefix.public}`
  },
  images: {
    // fileName, extension, altText: ['logo','png','Logo E-learning']
    header: [
      'logo-e-learning-met-lef-white__web',
      'png',
      'Cypress testing suite logo'
    ],
    footer: [
      'logo-e-learning-met-lef-color__web',
      'png',
      'Cypress testing suite logo'
    ],
    intro: ['intro--studying-in-isolation__web', 'jpg', 'Cypress testing suite']
  },
  copy: {
    // supports MD
    intro: 'Cypress testing suite',
    footer: 'Cypress testing suite'
  },
  signup: {
    customFields: ['profession']
  }
}
